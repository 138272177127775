import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { Badge } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useJobKPIs } from "../../../providers/jobsKPIsProvider";
import SidebarMenu from "./SidebarMenu";

const SidebarItem = ({
  name,
  badgeColor,
  badgeText,
  icon,
  to,
  action,
  counting,
  entries,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobKPIsContext] = useJobKPIs();

  return entries ? 
    (<SidebarMenu
    name={name}
    icon={icon}
    to={to}
    entries={entries}
    hasParent={true}/>
  ) : (
    <li
      id={name}
      className={`sidebar-item ${location.pathname === to ? "active" : ""}`}
      onClick={() => (action ? action() : navigate(to))}
    >
      <NavLink
        to="#"
        className={({ isActive }) =>
          `sidebar-link text-truncate ${isActive ? "active" : "none"}`
        }
      >
        {icon ? (
          <React.Fragment>
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className="align-middle mx-2 sidebar-icon"
            />
            <span className="align-middle">{name}</span>
          </React.Fragment>
        ) : (
          <span>
            {name}
            {counting ? (
              <b
                className={`${
                  jobKPIsContext[counting] <= 0 ? "bg-dark" : "bg-danger"
                } text-white m-0 ml-1 p-1 py-0`}
                style={{ fontSize: "9px", borderRadius: "50%" }}
              >
                {jobKPIsContext[counting]}
              </b>
            ) : null}
          </span>
        )}
        {badgeColor && badgeText !== undefined ? (
          <Badge
            style={{ paddingTop: "4px" }}
            color={badgeColor}
            size={18}
            className="sidebar-badge"
          >
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );
};

export default SidebarItem;
