// Auth
import SignIn from "../pages/auth/signIn/SignIn";

import {
  faUsers,
  faHome,
  faChartBar,
  faUser,
  faCogs,
  faSyncAlt,
  faListAlt,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";

import Users from "../pages/admin/Users";
import ResetPassword from "../pages/auth/ResetPassword";
import RecoverPassword from "../pages/auth/RecoverPassword";
import Jobs from "../pages/admin/Jobs";
import AttendanceSync from "../pages/admin/AttendanceSync";
import TeklaSync from "../pages/admin/TeklaSync";
import Customers from "../pages/admin/Customers";
import JobDetails from "../pages/admin/JobDetails";
import Settings from "../pages/admin/Settings";
import Detailing from "../pages/admin/Detailing";
import DetailerCheckerDashboard from "../pages/admin/DetailerCheckerDashboard";
import SalesReport from "../pages/admin/reports/SalesReport";
import JobBookReport from "../pages/admin/reports/JobBookReport";
import JobBookReportEva from "../pages/admin/reports/JobBookReportEva";
import AnnualSalesReport from "../pages/admin/reports/AnnualSalesReport";
import AnnualSalesReportSheet from "../pages/admin/reports/AnnualSalesReportSheet";
import CustomerSummaryReport from "../pages/admin/reports/CustomerSummaryReport";
import WeeklySummaryReport from "../pages/admin/reports/WeeklySummaryReport";
import CrewOsSync from "../pages/admin/CrewOsSync";
import InvoicingEmailNotifications from "../pages/admin/InvoicingEmailNotifications";

const ADMIN_ROLE_ID = 1;
const ESTIMATOR_ROLE_ID = 2;
const DETAILING_MANAGER_ROLE_ID = 3;
const DETAILER_CHECKER_ROLE_ID = 4;
const PROJECT_ASSISTANT_ROLE_ID = 5;
const ROLE_IDS = [
  ADMIN_ROLE_ID,
  DETAILING_MANAGER_ROLE_ID,
  DETAILER_CHECKER_ROLE_ID,
];

// Routes
export const adminRoutes = [
  {
    path: "/rfq",
    name: `RFQ`,
    icon: faHome,
    sidebar: true,
    entries: [
      {
        icon: null,
        name: "All",
        path: `/rfq/all`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Estimating",
        path: `/rfq/in-progress`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Pending",
        path: `/rfq/pending`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Ordered",
        path: `/rfq/ordered`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "No Quote",
        path: `/rfq/no-quote`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Not Awarded",
        path: `/rfq/not-awarded`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Archived",
        path: `/rfq/archived`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
    ],
    roles: [
      ADMIN_ROLE_ID,
      ESTIMATOR_ROLE_ID,
      DETAILING_MANAGER_ROLE_ID,
      PROJECT_ASSISTANT_ROLE_ID,
    ],
  },
  {
    path: "/jobs",
    name: "Jobs",
    icon: faHome,
    sidebar: true,
    entries: [
      {
        icon: null,
        name: "All Jobs",
        path: `/jobs/all`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "In Project Management",
        path: `/jobs/in-project-management`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "In Progress",
        path: `/jobs/in-progress`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Shipped",
        path: `/jobs/shipped`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Ready For Invoice",
        path: `/jobs/ready-for-invoice`,
        component: Jobs,
        sidebar: true,
        roles: [],
        counting: "inReadyForInvoice",
      },
      {
        icon: null,
        name: "Invoiced",
        path: `/jobs/invoiced`,
        component: Jobs,
        sidebar: true,
        roles: [],
        entries: [
          {
            name: "PM Approved",
            path: `/jobs/pm-approved`,
            component: Jobs,
            sidebar: true,
            roles: [],
            counting: "inPMApproved",
          },
          {
            name: "Exec Approved",
            path: `/jobs/exec-approved`,
            component: Jobs,
            sidebar: true,
            roles: [],
            counting: "inExecApproved",
          },
        ],
      },
      {
        icon: null,
        name: "Completed/Paid",
        path: `/jobs/completed`,
        component: Jobs,
        sidebar: true,
        roles: [],
      },
    ],
    roles: [
      ADMIN_ROLE_ID,
      ESTIMATOR_ROLE_ID,
      DETAILING_MANAGER_ROLE_ID,
      PROJECT_ASSISTANT_ROLE_ID,
    ],
  },
  {
    path: "/jobs/:id/details",
    name: "Job Details",
    component: JobDetails,
  },
  {
    path: "/rfq/:id/details",
    name: "Job Details",
    component: JobDetails,
  },
  {
    path: "/detailing",
    name: "Detailing",
    icon: faListAlt,
    sidebar: true,
    component: Detailing,
    roles: [
      ADMIN_ROLE_ID,
      ESTIMATOR_ROLE_ID,
      DETAILING_MANAGER_ROLE_ID,
      PROJECT_ASSISTANT_ROLE_ID,
    ],
  },
  {
    path: "/detailer-checker-dashboard",
    name: "Detailer/Checker",
    icon: faListAlt,
    sidebar: true,
    component: DetailerCheckerDashboard,
    roles: [DETAILER_CHECKER_ROLE_ID],
  },
  {
    path: "/reports",
    name: "Reports",
    icon: faChartBar,
    sidebar: true,
    entries: [
      {
        icon: null,
        path: `/reports/annual-sales-sheet`,
        component: AnnualSalesReportSheet,
        roles: [],
      },
      {
        icon: null,
        name: "Annual Sales Report",
        path: `/reports/annual-sales`,
        component: AnnualSalesReport,
        sidebar: true,
        roles: ROLE_IDS,
      },
      {
        icon: null,
        name: "Job Book Report",
        path: `/reports/job-book`,
        component: JobBookReport,
        sidebar: true,
        roles: [],
      },
      {
        icon: null,
        name: "Job Book Report EVA",
        path: `/reports/job-book-eva`,
        component: JobBookReportEva,
        sidebar: true,
        roles: [ADMIN_ROLE_ID],
      },
      {
        icon: null,
        name: "Sales Report",
        path: `/reports/sales`,
        component: SalesReport,
        sidebar: true,
        roles: ROLE_IDS,
      },
      {
        icon: null,
        name: "Customer Summary Report",
        path: `/reports/customer-summary`,
        component: CustomerSummaryReport,
        sidebar: true,
        roles: ROLE_IDS,
      },
      {
        icon: null,
        name: "Weekly Summary Report",
        path: `/reports/weekly-summary-report`,
        component: WeeklySummaryReport,
        sidebar: true,
        roles: ROLE_IDS,
      },
    ],
    roles: [ADMIN_ROLE_ID, ESTIMATOR_ROLE_ID, DETAILING_MANAGER_ROLE_ID],
  },
  {
    path: "/customers",
    name: "Customers",
    icon: faUser,
    sidebar: true,
    entries: [
      {
        icon: null,
        name: "Customers",
        path: `/customers`,
        component: Customers,
        sidebar: true,
        roles: [],
      },
    ],
    roles: [
      ADMIN_ROLE_ID,
      ESTIMATOR_ROLE_ID,
      DETAILING_MANAGER_ROLE_ID,
      PROJECT_ASSISTANT_ROLE_ID,
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    icon: faCogs,
    sidebar: true,
    entries: [
      {
        icon: faUsers,
        name: "Users",
        path: `/admin/users`,
        component: Users,
        sidebar: true,
        roles: [],
      },
      {
        icon: faSyncAlt,
        name: "Attendance Data Sync",
        path: `/admin/attendance-sync`,
        component: AttendanceSync,
        sidebar: true,
        roles: [],
      },
      {
        icon: faSyncAlt,
        name: "Tekla Data Sync",
        path: `/admin/tekla-sync`,
        component: TeklaSync,
        sidebar: true,
        roles: [],
      },
      {
        icon: faSyncAlt,
        name: "CrewOs Data Sync",
        path: `/admin/crewos-sync`,
        component: CrewOsSync,
        sidebar: true,
        roles: [],
      },
      {
        icon: faCogs,
        name: "General Settings",
        path: `/admin/settings`,
        component: Settings,
        sidebar: true,
        roles: [],
      },
      {
        icon: faMailBulk,
        name: "Invoicing Notifications",
        path: `/admin/invoicing-email-notifications`,
        component: InvoicingEmailNotifications,
        sidebar: true,
        roles: [ADMIN_ROLE_ID, PROJECT_ASSISTANT_ROLE_ID],
      },
    ],
    roles: [
      ADMIN_ROLE_ID,
      ESTIMATOR_ROLE_ID,
      DETAILING_MANAGER_ROLE_ID,
      PROJECT_ASSISTANT_ROLE_ID,
    ],
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Ingresar",
    component: SignIn,
  },
  {
    path: "/auth/reset",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/auth/forgot/:code",
    name: "Recover Password",
    component: RecoverPassword,
  },
];
