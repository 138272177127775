import React, { useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Loader from "../Loader";

import InformationModal from "../InformationModal";

const AddNotificationEmailModal = ({ submitFunction, onSubmit, onClose }) => {
  const [email, setEmail] = useState();
  const [type, setType] = useState("");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await submitFunction({
        email,
        type,
      });
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Add Notification Email",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add Notification Email
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label for="email">
                    <span>Email</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Enter the email..."
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label>
                    <span>Type</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <CustomInput
                    id="typeSelect"
                    type="select"
                    name="typeSelect"
                    value={type || ""}
                    required={true}
                    onChange={(evt) => setType(evt.currentTarget.value)}
                  >
                    <option value="">Select a type..</option>
                    <option value="ADMIN">ADMIN</option>
                    <option value="EXEC">EXEC</option>
                    <option value="ACCOUNTING">ACCOUNTING</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddNotificationEmailModal;
