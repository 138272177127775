import React, { useState } from "react";
import SidebarItem from "./SidebarItem";
import { useLocation } from "react-router-dom";
import SidebarCategory from "./SidebarCategory";
import { useAuth } from "../../../providers/authProvider";

const SidebarMenu = ({ name, icon, to, entries, hasParent }) => {
  const location = useLocation();

  const [authContext] = useAuth();
  const [isOpen, setIsOpen] = useState(
    to ? location.pathname.indexOf(to) === 0 : 0
  );

  return (
    <React.Fragment>
      <SidebarCategory
        name={name}
        icon={icon}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        hasParent={hasParent}
      >
        {entries
          .filter(
            (entry) =>
              entry.sidebar &&
              (!entry.roles?.length ||
                entry.roles?.indexOf(authContext.currentUser.userRoleId) > -1)
          )
          .map((entry, index) => (
            <SidebarItem
              key={index}
              icon={entry.icon}
              name={entry.name}
              to={entry.path}
              action={entry.action}
              counting={entry.counting}
              entries={entry.entries}
            />
          ))}
      </SidebarCategory>
    </React.Fragment>
  );
};

export default SidebarMenu;
