import classnames from "classnames";
import moment from "moment";
import React, { useState } from "react";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Badge,
  Button,
} from "reactstrap";
import JobSummaryReport from "../reports/JobSummaryReport";
import JobInvoiceAttachment from "./JobInvoiceAttachment";
import OrderAcknowledgmentList from "./OrderAcknowledgmentList";
import { jobInvoicesApi } from "../../../../services/jobInvoicesServices";
import { useAuth } from "../../../../providers/authProvider";
import ConfirmationModal from "../../../ConfirmationModal";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";
import { utils } from "../../../../utils/utils";
import { jobsApi } from "../../../../services/jobServices";
import {
  ACTIONS as KPI_ACTIONS,
  useJobKPIs,
} from "../../../../providers/jobsKPIsProvider";

const INVOICE_PDF = "INVOICE_PDF";
const ORDER_ACK = "ORDER_ACK";
const QUOTE_PDF = "QUOTE_PDF";
const APPROVED = "APPROVED";
const PENDING = "PENDING";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const JobInvoiceDetail = ({ jobInvoice, setRefresh, job }) => {
  const [authContext] = useAuth();

  const [tabSelected, setTabSelected] = useState(INVOICE_PDF);
  const [loading, setLoading] = useState();
  const [, setJobKPIsContext] = useJobKPIs();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onUnapprove = (jobInvoice) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        await jobInvoicesApi.updateJobInvoice({
          ...jobInvoice,
          status: PENDING,
        });
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Unapprove Job Invoice",
          body: "Job Invoice Unapproved Successfully",
          onClose: () => {
            setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            });
            setRefresh((refresh) => !refresh);
          },
        });
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Unapprove Job Invoice",
      body: `
                <div class="text-center">
                    Do you confirm you want to unapprove this invoice?
                </div>
          `,
      confirmColor: "warning",
    });
  };

  const onApprove = (jobInvoice) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          setConfirmationModal(initConfirmationModal);
          setLoading(true);
          const response = await jobInvoicesApi.updateJobInvoice({
            ...jobInvoice,
            status: APPROVED,
            approvedBy: authContext.currentUser.id,
          });
          await updateJobKPIsContext();
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Approve Job Invoice",
            body: response.errorMessage || "Job Invoice Approved Successfully",
            onClose: () => {
              setInformationModal({
                isOpen: false,
                title: "",
                body: "",
                onClose: null,
              });
              setRefresh((refresh) => !refresh);
            },
          });
        } catch (error) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Approve Job Invoice",
            body: "Job Invoice Can't be Approved.",
            onClose: () => {
              setInformationModal({
                isOpen: false,
                title: "",
                body: "",
                onClose: null,
              });
            },
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      title: "Approve Job Invoice",
      body: `
                <div class="text-center">
                    Do you confirm you want to approve this invoice?<br/>Notification emails will be sent
                </div>
          `,
      confirmColor: "warning",
    });
  };

  const updateJobKPIsContext = async () => {
    setJobKPIsContext({
      action: KPI_ACTIONS.GET_DATA_LOADING,
      payload: true,
    });
    const kpis = await jobsApi.getJobsKPIs({});
    setJobKPIsContext({
      action: KPI_ACTIONS.GET_DATA_SUCCESS,
      payload: { kpis },
    });
  };

  return loading ? (
    <Loader size="sm" />
  ) : (
    <div>
      <Nav
        tabs
        className="d-flex text-center flex-nowrap job-details-nav cursor-pointer mt-2 w-100"
      >
        <div className="d-flex flex-grow-1 align-items-center justify-content-between">
          <div className="d-flex flex-grow-1">
            <NavItem>
              <NavLink
                className={classnames({
                  active: tabSelected === INVOICE_PDF,
                })}
                onClick={() => setTabSelected(INVOICE_PDF)}
              >
                Invoice PDF
              </NavLink>
            </NavItem>
            {moment(job.createdAt).isSameOrAfter(utils.RELESASE_DATE) ||
            jobInvoice.jobInvoiceOrderAcknowledgments?.length ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: tabSelected === ORDER_ACK,
                  })}
                  onClick={() => setTabSelected(ORDER_ACK)}
                >
                  Order ACK
                </NavLink>
              </NavItem>
            ) : null}

            <NavItem>
              <NavLink
                className={classnames({
                  active: tabSelected === QUOTE_PDF,
                })}
                onClick={() => setTabSelected(QUOTE_PDF)}
              >
                Quote PDF
              </NavLink>
            </NavItem>
          </div>
          <>
            {!jobInvoice.pmApproval ? (
              <Button
                size="sm"
                className="rounded mr-2"
                color="success"
                onClick={() => onApprove({ ...jobInvoice, pmApproval: true })}
              >
                PM Approval
              </Button>
            ) : null}
            {!jobInvoice.execApproval ? (
              <Button
                size="sm"
                className="rounded mr-2"
                color="success"
                onClick={() => onApprove({ ...jobInvoice, execApproval: true })}
              >
                Exec Approval
              </Button>
            ) : null}
            <Badge color="info">
              Approved by{" "}
              {jobInvoice?.approvedByUser
                ? `${jobInvoice.approvedByUser.firstName} ${jobInvoice.approvedByUser.lastName}`
                : "-"}
            </Badge>
            <Button
              size="sm"
              className="rounded ml-2"
              color="danger"
              onClick={() => onUnapprove(jobInvoice)}
            >
              Unapprove
            </Button>
          </>
        </div>
      </Nav>
      <TabContent activeTab={tabSelected} className="w-100">
        <TabPane tabId={INVOICE_PDF}>
          <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
            <CardBody>
              {jobInvoice.invoicePDF ? (
                <JobInvoiceAttachment attachmentUrl={jobInvoice.invoicePDF} />
              ) : (
                "No Invoice Available"
              )}
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId={ORDER_ACK}>
          <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
            <CardBody>
              <OrderAcknowledgmentList
                items={jobInvoice.jobInvoiceOrderAcknowledgments.map(
                  (item) => item.orderAcknowledgment
                )}
              />
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId={QUOTE_PDF}>
          <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
            <CardBody>
              <JobSummaryReport />
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          size={informationModal.size}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default JobInvoiceDetail;
